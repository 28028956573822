<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-radio-group class="vg_mr_8" size="small" v-model="pageName" @change="changeInspectionType">
          <el-radio-button :label="0">未申请</el-radio-button>
          <el-radio-button :label="1">部分申请</el-radio-button>
          <el-radio-button :label="2">已申请</el-radio-button>
        </el-radio-group>
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange1"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="交期开始日期"
          end-placeholder="交期结束日期"
        />
        <el-button type="primary" size="small" class="vg_mt_8" @click="generateInspectionRequestForm()">生成验货申请单</el-button>
        <el-button v-if="pageName !== 2" type="danger" size="small" class="vg_mt_8" @click="noNeedInspection()">无需验货</el-button>
        <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()">导出Excel</el-button>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getInspectionList()" class="vg_mt_8">查询 </el-button>
        <el-button type="info" size="small" class="vg_mt_8" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button v-if="pageName !== 2" size="small" type="primary" @click="joinThePendingInspectionList()" class="vg_mt_8">加入待验列表 </el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'inspection_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getInspectionList"
        :row-style="setRowStyle"
        :selectable="row => row.inspection_status <= 1"
      >
      </search-table>
      <el-button
        style="position: absolute; bottom: 100px; right: 50px"
        type="primary"
        icon="el-icon-s-fold"
        circle
        @click="listOfPendingInspectionsDialogVisible = true"
      />
      <el-dialog title="待验列表" :visible.sync="listOfPendingInspectionsDialogVisible" width="70%">
        <el-button type="success" size="small" class="vg_mt_8" @click="dialogGenerateInspectionRequestForm()">生成验货申请单</el-button>
        <el-button type="primary" size="small" class="vg_mt_8" @click="listOfPendingInspectionsDialogVisible = false">返回添加</el-button>
        <el-button type="danger" size="small" class="vg_mt_8" @click="deleteDialogTableRow()">删除</el-button>
        <DynamicUTable
          ref="dialogTable"
          :table-data="computedDialogTableData"
          :needPagination="false"
          :need-fixed-height="true"
          :need-search="false"
          :columns="dialogTableProperties"
          @selection-change="val => (dialogSelection = val)"
        ></DynamicUTable>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { inspectionAPI } from '@api/modules/inspection';
import { cloneDeep } from 'lodash';
import { dialogTableProperties, tableProperties } from '@/views/InspectionManagement/InspectionManage/inspection';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { downloadFile } from '@api/public';
import DynamicUTable from '@/components/table/DynamicUTable.vue';

export default {
  name: 'InspectionList',
  components: {
    DynamicUTable,
    SearchTable
  },
  data() {
    return {
      dateRange1: [],
      tableProperties: cloneDeep(tableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentRow: {},
      changeColumn: [],
      pageName: 0,
      listOfPendingInspectionsDialogVisible: false,
      dialogTableData: [],
      dialogTableProperties: dialogTableProperties,
      dialogSelection: []
    };
  },
  computed: {
    computedDialogTableData() {
      return this.dialogTableData.filter(x => x.destroy_flag !== 1);
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.multiTable.searchForm.inspection_status = 0;
      this.getInspectionList();
      this.changeProperties();
    },
    async changeProperties() {},
    async getInspectionList() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange1 || [];
      searchForm.startTime = startTime ? startTime : null;
      searchForm.endTime = endTime ? endTime : null;
      await inspectionAPI.getInspection(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    buttonRefresh() {
      this.dateRange1 = [];
      this.pageName = '';
      this.$refs.multiTable.resetFields();
      this.changeInspectionType(0);
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    generateInspectionRequestForm() {
      if (this.multiSelection.length === 0) return this.$message.warning('请选择数据!');
      this.$router.push({
        name: 'ApplicationAdd',
        query: { perm_id: 185, t: Date.now() },
        params: { syncData: cloneDeep(this.multiSelection) }
      });
      this.$refs.multiTable.clearSelection();
    },
    dialogGenerateInspectionRequestForm() {
      if (this.dialogSelection.length === 0) return this.$message.warning('请选择数据!');
      this.listOfPendingInspectionsDialogVisible = false;
      this.$router.push({
        name: 'ApplicationAdd',
        query: { perm_id: 185, t: Date.now() },
        params: { syncData: cloneDeep(this.dialogSelection) }
      });
      this.dialogTableData = [];
      this.$refs.dialogTable.clearSelection();
    },
    noNeedInspection() {
      if (this.multiSelection.length === 0) return this.$message.warning('请选择数据!');
      inspectionAPI.editInspectionProd(this.multiSelection).then(() => {
        this.$message.success('保存成功!');
        this.$refs.multiTable.clearSelection();
        this.getInspectionList();
      });
    },
    exportExcel() {
      let requestBody = { type: 1, ...this.$refs.multiTable.searchForm };
      inspectionAPI.exportExcel(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.pdf_url, fileName: '成品验货清单', type: '.xlsx' });
      });
    },
    async changeInspectionType(type) {
      await this.$refs.multiTable.resetFields();
      await (this.$refs.multiTable.searchForm.inspection_status = type);
      await this.getInspectionList();
    },
    joinThePendingInspectionList() {
      if (this.multiSelection.length === 0) return this.$message.warning('请选择数据!');
      this.multiSelection.forEach(item => {
        let find = this.dialogTableData.find(x => x.inspectionprod_id === item.inspectionprod_id);
        if (!find) this.dialogTableData.push(cloneDeep(item));
      });
      this.$refs.multiTable.clearSelection();
      this.$message.success('操作成功!');
    },
    deleteDialogTableRow() {
      this.dialogSelection.forEach(item => this.$set(item, 'destroy_flag', 1));
    },
    setRowStyle({ row }) {
      if (Number(row.podr_part_num) === 0) {
        return { background: 'yellow' };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
